var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "container-fluid", attrs: { id: "company-select-slide" } },
    [
      _c("slide-heading", { attrs: { heading: _vm.currentSlide.heading } }),
      _c("slide-image-and-description", { attrs: { slide: _vm.currentSlide } }),
      _vm.loaded
        ? [
            _c("h4", [_vm._v("\n      Businesses Needing Review\n    ")]),
            _c("company-selector", {
              attrs: { companies: _vm.scopedCompanies },
              on: { "company-selected": _vm.companySelected },
            }),
          ]
        : _c("ct-centered-spinner", [
            _vm._v("\n    " + _vm._s(_vm.loadingText) + "\n  "),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }