<template>
  <b-container
    id="company-select-slide"
    class="container-fluid"
  >
    <slide-heading :heading="currentSlide.heading" />
    <slide-image-and-description :slide="currentSlide" />

    <template v-if="loaded">
      <h4>
        Businesses Needing Review
      </h4>
      <company-selector
        :companies="scopedCompanies"
        @company-selected="companySelected"
      />
    </template>

    <ct-centered-spinner v-else>
      {{ loadingText }}
    </ct-centered-spinner>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CompanySelectSlide',
  components: {
    CompanySelector:   () => import('@/components/StagelineV2/shared/CompanySelector'),
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
    SlideHeading:      () => import('@/components/StagelineV2/shared/SlideHeading'),
    SlideImageAndDescription: () => import('@/components/StagelineV2/shared/SlideImageAndDescription.vue'),
  },
  data() {
    return {
      loaded: false,
      loadingText: 'Loading Companies...',
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'currentSlide',
      'accountCompanies',
    ]),
    scopedCompanies() {
      return this.accountCompanies.filter(c => c?.stageline?.route?.status_key === 'needs_corporate_transparency_act_check')
    },
  },
  async mounted() {
    if (!this.accountCompanies.length) await this.getAccountCompanies()
    this.loaded = true
  },
  methods: {
    ...mapActions('companies', [
      'setCurrentCompany',
    ]),
    ...mapActions('stageline', [
      'getAccountCompanies',
    ]),
    async companySelected(selectedCompany) {
      this.loadingText = 'Loading Company...'
      this.loaded = false
      await this.setCurrentCompany({ id: selectedCompany.id, preserveStageline: true })
      this.$emit('next-slide')
    },
  },
}
</script>
